<template>
  <div class="h-100vh position-relative bg-light-gray text-center">
    <div class="d-flex justify-content-center align-items-center w-100 complain-container">
      <div class="w-100 my-3 text-center">
        <div class="wrap-logo d-flex justify-content-center align-items-center">
          <img :src="logo" class="logo-img" alt="Logo" />
        </div>
        <div class="d-flex justify-content-center f-size-18 mt-3">
          <div>
            <div class="text-center">
              <span v-if="isLoading">{{ $t("systemSendingToEmployee") }} <br /> {{ $t("pleaseWait") }}</span>
              <span v-else-if="result == 1">{{ $t("pleasePressOpenChatPage") }}</span>
              <span v-else>{{ $t("failed") }}</span>
            </div>
            <div class="mt-5" v-if="isLoading">
              <b-img center :src="gifLoading" width="100" alt="loading" />
            </div>
            <div class="mt-5" v-else-if="result == 1">
              <font-awesome-icon icon="check-circle" class="icon-success" />
              <p class="my-3">{{ $t("thankForWaiting") }}</p>
              <b-button variant="main-2" class="px-5" @click="goToChat">
                {{ $t("chatNow") }}
              </b-button>
            </div>
            <div class="mt-3" v-else>
              <span class="text-danger f-size-14">{{ modalMessage || $t("failed") }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalLoading ref="modalLoading" :hasClose="false" />
    <ModalAlert
      ref="modalAlert"
      :text="modalMessage"
      v-on:hadleModalAlertClose="hadleModalAlertClose"
    />
    <ModalAlertError
      ref="modalAlertError"
      :text="modalMessage"
    />
  </div>
</template>

<script>
import ModalLoading from "@/components/alert-modal/ModalLoading";
import ModalAlert from "@/components/alert-modal/ModalAlert";
import ModalAlertError from "@/components/alert-modal/ModalAlertError";
import GifLoading from "@/assets/images/loading-d-dots.gif";
import { mapGetters } from "vuex";
import { useFavicon } from '@vueuse/core'

export default {
  components: {
    ModalLoading,
    ModalAlert,
    ModalAlertError
  },
  data() {
    return {
      lineProfile: {
        userId: "",
        displayName: "",
        pictureUrl: "",
        statusMessage: ""
      },
      modalMessage: "",
      isLoading: true,
      result: 0,
      gifLoading: GifLoading,
      brandId: this.$route.query.brandId
    };
  },
  beforeCreate: async function () {
    if (this.$liff.isInClient()) {
      await this.$liff
        .init({ liffId: this.$liff_Agent_QR_Code })
        .then(async () => {
          await this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
          });
          await this.submit();
        })
        .catch(error => {
          console.error("error", error);
        });
    }
  },
  async mounted () {
    this.brandId = this.$route.query.brandId;
    if(this.brandId) this.getLogo();
  },
  computed: {
    ...mapGetters({
      isLoadingMainApp: "getIsLoadingMainApp",
      logo: "getLogo"
    }),
  },
  methods: {
    hadleModalAlertClose() {
      if (this.$liff.isInClient()) {
        this.$liff.closeWindow();
      } else {
        this.$router.push("/success/complain");
      }
    },
    goToChat() {
      window.location.href = this.$line_Chat_Url;
      if (this.$liff.isInClient()) {
        setTimeout(() => {
          this.hadleModalAlertClose();
        }, 1000);
      } else {
        setTimeout(() => {
          window.close();
        }, 1000);
      }
    },
    submit: async function () {
      this.isLoading = true;
      let body = {
        SocialReferenceId: this.lineProfile.userId,
        AgentGUID: this.$route.query.guid,
        PlatFormId: 1,
        BrandId: this.$route.query.brandId
      }
      await this.$axios
        .post(`${this.$baseUrl}/form/agent-fix-customer`, body)
        .then(data => {
          if (data.data.result == 0) {
            this.modalMessage = data.data.message;
            this.$refs.modalAlertError.show();
          }
          this.result = data.data.result;
          this.isLoading = false;
        }).catch(error => {
          this.modalMessage = error;
          this.$refs.modalAlertError.show();
          this.isLoading = false;
        });
    },
    getLogo: async function () {
      await this.$store.dispatch("setIsLoadingMainApp", true);
      await this.$axios.get(`${this.$baseUrl}/system/brandlogo/${this.brandId}`).then(async data => {
        if (data.data.result == 1) {
          await this.$store.dispatch("setIsLoadingMainApp", false);
          const icon = useFavicon();
          icon.value = data.data.detail;
          await this.$store.dispatch("setLogo", data.data.detail);
        } else {
          this.$store.dispatch("setIsLoadingMainApp", false);
        }
      }).catch(error => {
        console.log("error brandlogo", error);
        this.$store.dispatch("setIsLoadingMainApp", false);
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.text-error {
  color: #dc3545 !important;
  font-weight: bold;
  font-size: 16px;
}

.complain-container {
  height: calc(100vh - 60.5px);
}

::v-deep .card {
  border-color: white;
  border-radius: 14px;
}
.logo-img {
  width: 80% !important;
  height: auto;
  max-height: 80%;
  max-width: 80%;
}
.wrap-logo {
  width: 180px;
  height: 180px;
  margin: auto;
  border: 1px solid #dee2e6;
  border-radius: 15px;
}
.icon-success {
  color: #27bd7c;
  font-size: 4rem;
}
@media only screen and (min-width: 767.98px) {
  .text-error {
    font-size: 15px;
  }
}
</style>
